.App {
    /* width: 100%; */
    /* height: 100%; */
    margin: 0;
    padding: 10px;
    
    overflow-x: hidden; 
}

body {
   
    min-height: 100vh;
   
}

.svgcell {
    -webkit-filter: invert(75%); /* safari 6.0 - 9.0 */
            filter: invert(75%);
  }

.lightRoot {
    
    background-color: #f2f2f2;
   min-height: 100vh;

}

.darkRoot {
    
    background-color: #000000;
    min-height: 100vh;
}

